import {useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps, FormikValues} from 'formik';
import {isNaN} from 'lodash';

import {FormikCheckboxGroup, FormikInput, FormikNumberInput} from '@reasoncorp/kyber-js';

import {formatDecimal} from '../../../utils';
import {form4015aSchema} from '../../../schemas';
import {forms} from '../../../types';

type Props = {
  property?: forms.Form4015aPropertyDto
  isOpen: boolean
  operation: 'Add' | 'Edit'
  classificationFilter: string | number
  onToggle: (operation: 'Add' | 'Edit', property?: forms.Form4015aPropertyDto) => void
  version: string
}

const Form4015aPropertyModal = ({
                                  isOpen,
                                  operation,
                                  property,
                                  onToggle,
                                  classificationFilter,
                                  version
                                }: Props) => {
  const validationSchema = useMemo(() => {
    return form4015aSchema(Number(classificationFilter));
  }, [
    classificationFilter
  ]);

  const initialValues = useMemo(() => ({
    id: property?.id || 0,
    propertyNumber: property?.propertyNumber || '',
    ownerName: property?.ownerName || '',
    propertyClassification: property?.propertyClassification || null,
    assessedValue: property?.assessedValue || null,
    appraisedValue: property?.appraisedValue || null,
    comments: property?.comments || '',
    stratified: property?.stratified ?? null
  }), [
    property
  ]);

  const handleToggle = useCallback((formikProps: FormikProps<any>) => {
    onToggle(operation);
    formikProps.resetForm();
  }, [
    onToggle,
    operation
  ]);

  const handleSubmit = useCallback(async (values: FormikValues, actions: FormikHelpers<any>) => {
    const valuesToSubmit = {
      ...values,
      assessedValue: Number(values.assessedValue),
      appraisedValue: Number(values.appraisedValue),
      propertyClassification: Number(values.propertyClassification)
    } as forms.Form4015aPropertyDto;
    onToggle(operation, valuesToSubmit);
    actions.setSubmitting(false);
    actions.resetForm();
  }, [
    onToggle,
    operation
  ]);
  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal size="lg"
               isOpen={isOpen}
               toggle={() => handleToggle(formikProps)}
               autoFocus={false}
               returnFocusAfterClose={true}
               className="Form4015aParcelModal">
          <ModalHeader toggle={() => handleToggle(formikProps)} className="h5" tag="h2">
            {operation === 'Add' ? 'Add Parcel' : 'Edit Parcel'}
          </ModalHeader>
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <ModalBody>
              <Row>
                <Col className="col-4">
                  <FormikInput name="propertyNumber"
                               labelText="Parcel Code"
                               autoFocus
                               aria-required={true}
                               maxLength="30"/>
                </Col>
                <Col className="col-8">
                  <FormikInput name="ownerName"
                               labelText="Owners Name"
                               maxLength="50"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-3">
                  <FormikNumberInput name="propertyClassification"
                                     labelText="Class Code"
                                     aria-required={true}
                                     maxLength="3"/>
                </Col>
                <Col className="col-3">
                  <FormikNumberInput name="assessedValue"
                                     labelText="Assessed Value"
                                     aria-required={true}
                                     maxLength="15"/>
                </Col>
                <Col className="col-3">
                  <FormikNumberInput name="appraisedValue"
                                     labelText="Appraised Value"
                                     aria-required={true}
                                     maxLength="15"/>
                </Col>
                <Col className="col-3 mt-3">
                  <div>Ratio</div>
                  <div>
                    {
                      formikProps.values.appraisedValue && formikProps.values.assessedValue && !isNaN(formikProps.values.assessedValue / formikProps.values.appraisedValue) ?
                        formatDecimal(formikProps.values.assessedValue / formikProps.values.appraisedValue, 2, true)
                        : '0.00%'
                    }
                  </div>
                </Col>
              </Row>
              {version === 'V2' && <Row className="mt-3 mb-2">
                <Col>
                  <FormikCheckboxGroup inline
                                       type="switch"
                                       checkboxes={[{
                                         name: 'stratified',
                                         labelText: 'Stratified'
                                       }]}/>
                </Col>
              </Row>}
              {version === 'V1' && <Row>
                <Col className="col-12">
                  <FormikInput name="comments"
                               labelText="Comments"
                               maxLength="200"/>
                </Col>
              </Row>}
            </ModalBody>
            <ModalFooter>
              <Button color="primary"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}>
                {operation === 'Add' ? 'Add' : 'Edit'}
              </Button>
              <Button color="secondary"
                      onClick={() => handleToggle(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default Form4015aPropertyModal;