import {useMemo} from 'react';
import {FormikProps} from 'formik';
import {Col, Row, Table} from 'reactstrap';

import {FormikNumberInput, FormikPercentageInput} from '@reasoncorp/kyber-js';

import {formatDecimal, formatInteger, isNumber} from '../../../utils';
import * as form4027iUtils from '../../../utils/form4027iUtils';
import {Form4027iPersonalPropertyClassificationDto} from '../../../types/forms/form4027i';

type Props = {
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  showReadOnlyView: boolean
  formikProps: FormikProps<any>
  isAugustStatusEditable: boolean
  isJuneStatusEditable: boolean
  formType: string
}

const Form4027iPersonalPropertyTab = ({
                                        setHasUnsavedChanges,
                                        showReadOnlyView,
                                        formikProps,
                                        isAugustStatusEditable,
                                        isJuneStatusEditable,
                                        formType
                                      }: Props) => {
  const classificationCalculations = useMemo(() => form4027iUtils.getClassificationCalculations(
    formType,
    formikProps,
    'personalProperty'
  ), [
    formType,
    formikProps
  ]);

  const {
    june30Status,
    august31Status,
    october31Status
  } = useMemo(() => {
    return formikProps.values
      .classifications
      .personalProperty as Form4027iPersonalPropertyClassificationDto;
  }, [
    formikProps
  ]);

  const numberOfParcelsInStudy = useMemo(() => form4027iUtils.getNumberOfParcelsInStudy(
    formikProps,
    'personalProperty'
  ), [
    formikProps
  ]);

  const june30NumberOfParcelsInStudy = useMemo(() => {
    return formType === 'FORM_4027I_JUNE' ?
      numberOfParcelsInStudy :
      june30Status?.numberOfParcelsInStudy ?? null;
  }, [
    formType,
    numberOfParcelsInStudy,
    june30Status
  ]);

  const june30StatusParcelSelectionPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations?.june30Status?.parcelSelectionPercentCompleted)) {
      return classificationCalculations?.june30Status?.parcelSelectionPercentCompleted ?? 0;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const august31StatusParcelSelectionPercentCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations?.august31Status?.parcelSelectionPercentCompleted)) {
      return classificationCalculations?.august31Status?.parcelSelectionPercentCompleted ?? 0;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  const october31StatusParcelSelectionCompleted = useMemo(() => {
    if (numberOfParcelsInStudy !== 0 &&
      isNumber(classificationCalculations?.october31Status?.parcelSelectionPercentCompleted)) {
      return classificationCalculations?.october31Status?.parcelSelectionPercentCompleted ?? 0;
    } else {
      return 0;
    }
  }, [
    numberOfParcelsInStudy,
    classificationCalculations
  ]);

  return <>
    <Row className="mt-4"><Col>
      <Table className="mb-0" bordered responsive key="june-personal-property-table">
        <thead>
          <tr>
            <th className="text-nowrap text-primary text-left w-30">Actual Status as of June 30th</th>
            <th className="text-nowrap text-primary text-center w-25">Number of Parcels in Study</th>
            <th className="text-nowrap text-primary text-center w-25">Number of Parcels Completed</th>
            <th className="text-nowrap text-primary text-center w-20">Percent Completed</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-middle text-nowrap">Parcel Selection</td>
            <td className="align-middle text-center">
              {formatInteger(june30NumberOfParcelsInStudy)}
            </td>
            <td className="align-middle text-center">
              {isJuneStatusEditable && <FormikNumberInput bsSize="sm"
                                                          formGroupClass="mb-0"
                                                          onChange={() => setHasUnsavedChanges(true)}
                                                          maxLength="9"
                                                          disableFloatingLabel={true}
                                                          className="text-center"
                                                          ariaLabel="Parcel Selection"
                                                          name="classifications.personalProperty.june30Status.parcelSelection"/>}
              {!isJuneStatusEditable && formatInteger(june30Status?.parcelSelection ?? 0)}
            </td>
            <td className="align-middle text-center">
              {formatDecimal(june30StatusParcelSelectionPercentCompleted, 2, true)}
            </td>
          </tr>
          <tr>
            <td className="align-middle text-nowrap">Audit / Review</td>
            <td className="align-middle text-center">&nbsp;</td>
            <td className="align-middle text-center">&nbsp;</td>
            <td className="align-middle text-center">
              {isJuneStatusEditable && <FormikPercentageInput bsSize="sm"
                                                              formGroupClass="mb-0"
                                                              onChange={() => setHasUnsavedChanges(true)}
                                                              maxLength="7"
                                                              disableFloatingLabel={true}
                                                              className="text-center"
                                                              ariaLabel="Audit / Review"
                                                              name="classifications.personalProperty.june30Status.auditReview"/>}
              {!isJuneStatusEditable && <span>
                {formatDecimal((june30Status?.auditReview ?? 0) / 100, 2, true)}
              </span>}
            </td>
          </tr>
        </tbody>
      </Table>
    </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Table className="mb-0" bordered responsive key="august-status-table">
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-30">
                {formType !== 'FORM_4027I_JUNE' ?
                  'Actual Status of Study August 31st'
                  : 'Planned Interim Status of Study August 31st'
                }
              </th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels in Study</th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels Completed</th>
              <th className="text-nowrap text-primary text-center w-25">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle text-nowrap">Parcel Selection</td>
              <td className="align-middle text-center">
                {formType !== 'FORM_4027I_OCT' ?
                  formatInteger(numberOfParcelsInStudy) :
                  formatInteger(august31Status?.numberOfParcelsInStudy ?? null)
                }
              </td>
              <td className="align-middle text-center">
                {isAugustStatusEditable && <FormikNumberInput bsSize="sm"
                                                              formGroupClass="mb-0"
                                                              onChange={() => setHasUnsavedChanges(true)}
                                                              maxLength="9"
                                                              disableFloatingLabel={true}
                                                              ariaLabel="Parcel Selection"
                                                              className="text-center"
                                                              name="classifications.personalProperty.august31Status.parcelSelection"/>
                }
                {!isAugustStatusEditable && formatInteger(august31Status?.parcelSelection ?? 0)}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(august31StatusParcelSelectionPercentCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Audit / Review</td>
              <td className="align-middle text-center">&nbsp;</td>
              <td className="align-middle text-center">&nbsp;</td>
              <td className="align-middle text-center">
                {isAugustStatusEditable && <FormikPercentageInput bsSize="sm"
                                                                  formGroupClass="mb-0"
                                                                  onChange={() => setHasUnsavedChanges(true)}
                                                                  maxLength="7"
                                                                  disableFloatingLabel={true}
                                                                  className="text-center"
                                                                  ariaLabel="Audit / Review"
                                                                  name="classifications.personalProperty.august31Status.auditReview"/>
                }
                {!isAugustStatusEditable && <span>
                  {formatDecimal((august31Status?.auditReview ?? 0) / 100, 2, true)}
                </span>}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Table className="mb-0" bordered responsive key="october-status-table">
          <thead>
            <tr>
              <th className="text-nowrap text-primary text-left w-30">
                {
                  formType === 'FORM_4027I_OCT' ?
                    'Actual Status of Study October 31st' :
                    'Planned Interim Status of Study October 31st'
                }
              </th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels in Study</th>
              <th className="text-nowrap text-primary text-center w-25">Number of Parcels Completed</th>
              <th className="text-nowrap text-primary text-center w-20">Percent Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle text-nowrap">Parcel Selection</td>
              <td className="align-middle text-center">
                {formatInteger(numberOfParcelsInStudy)}
              </td>
              <td className="align-middle text-center">
                {showReadOnlyView && formatInteger(october31Status?.parcelSelection ?? 0)}
                {!showReadOnlyView && <FormikNumberInput bsSize="sm"
                                                         formGroupClass="mb-0"
                                                         onChange={() => setHasUnsavedChanges(true)}
                                                         maxLength="9"
                                                         disableFloatingLabel={true}
                                                         ariaLabel="Parcel Selection"
                                                         className="text-center"
                                                         name="classifications.personalProperty.october31Status.parcelSelection"/>}
              </td>
              <td className="align-middle text-center">
                {formatDecimal(october31StatusParcelSelectionCompleted, 2, true)}
              </td>
            </tr>
            <tr>
              <td className="align-middle text-nowrap">Audit / Review</td>
              <td className="align-middle text-center">&nbsp;</td>
              <td className="align-middle text-center">&nbsp;</td>
              <td className="align-middle text-center">
                {showReadOnlyView && <span>
                    {formatDecimal((october31Status?.auditReview ?? 0) / 100, 2, true)}
                  </span>}
                {!showReadOnlyView && <FormikPercentageInput bsSize="sm"
                                                             formGroupClass="mb-0"
                                                             onChange={() => setHasUnsavedChanges(true)}
                                                             maxLength="7"
                                                             disableFloatingLabel={true}
                                                             ariaLabel="Audit / Review"
                                                             className="text-center"
                                                             name="classifications.personalProperty.october31Status.auditReview"/>}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  </>;
};

export default Form4027iPersonalPropertyTab;