import {useCallback, useMemo, useState} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';

import {CustomTable, SelectTableCell, SelectTableCellData} from '@reasoncorp/kyber-js';

import {FileUpload, LocalUnit} from '../../types';
import {areArraysEqual} from '../../utils';

type Props = {
  isOpen: boolean
  onCancel: () => void
  localUnits: LocalUnit[]
  fileUpload: FileUpload
  onShare: (localUnitIds: number[]) => void
  processing: boolean
}

type FileShareLocalUnit = LocalUnit & {
  selected: boolean
}

const FileShareModal = ({
                          isOpen,
                          onCancel,
                          localUnits,
                          fileUpload,
                          onShare,
                          processing
                        }: Props) => {
  const [hasChanged, setHasChanged] = useState(false);

  const fileShareLocalUnits = useMemo(() => {
    return localUnits.map(localUnit => ({
      ...localUnit,
      selected: fileUpload.sharedLocalUnitIds.includes(localUnit.id)
    } as FileShareLocalUnit));
  }, [
    fileUpload,
    localUnits
  ]);

  const [selectedFileShareLocalUnits, setSelectedFileShareLocalUnits] = useState<FileShareLocalUnit[]>(fileShareLocalUnits);

  const handleCancel = useCallback(() => {
    setSelectedFileShareLocalUnits(fileShareLocalUnits);
    onCancel();
  }, [
    fileShareLocalUnits,
    onCancel
  ]);

  const handleItemSelect = useCallback((data: SelectTableCellData) => {
    const updatedSelectedFileShareLocalUnits = selectedFileShareLocalUnits.map(localUnit => localUnit.id === data.itemId ? {
      ...localUnit,
      selected: !data.prevSelection
    } : localUnit);

    setHasChanged(!areArraysEqual(fileShareLocalUnits, updatedSelectedFileShareLocalUnits));
    setSelectedFileShareLocalUnits(updatedSelectedFileShareLocalUnits);
  }, [
    selectedFileShareLocalUnits,
    fileShareLocalUnits
  ]);

  const handleSelectAll = useCallback((data: SelectTableCellData) => {
    const updatedSelectedFileShareLocalUnits = selectedFileShareLocalUnits.map(localUnit => data.itemIds.includes(localUnit.id) ? {
      ...localUnit,
      selected: !data.prevSelection
    } : localUnit);

    setHasChanged(!areArraysEqual(fileShareLocalUnits, updatedSelectedFileShareLocalUnits));
    setSelectedFileShareLocalUnits(updatedSelectedFileShareLocalUnits);
  }, [
    selectedFileShareLocalUnits,
    fileShareLocalUnits
  ]);

  const handleShare = useCallback(() => {
    const selectedLocalUnitIds = selectedFileShareLocalUnits
      .filter(fileShareLocalUnit => fileShareLocalUnit.selected)
      .map(fileShareLocalUnit => fileShareLocalUnit.id);

    onShare(selectedLocalUnitIds);
  }, [
    selectedFileShareLocalUnits,
    onShare
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {
        selectKey: 'selected',
        dataIdKey: 'id',
        onChange: handleSelectAll,
        className: 'text-nowrap text-center w-10'
      },
      {
        title: 'Local Unit',
        className: 'text-nowrap w-90',
        sortKey: 'displayNameWithType'
      }
    ],
    items: selectedFileShareLocalUnits,
    renderRow: (fileUploadShareLocalUnit: FileShareLocalUnit) => {
      return <tr key={fileUploadShareLocalUnit.id}>
        <SelectTableCell itemId={fileUploadShareLocalUnit.id}
                         ariaLabel={`Toggle select ${fileUploadShareLocalUnit.displayNameWithType}`}
                         selected={fileUploadShareLocalUnit.selected}
                         onChange={handleItemSelect}/>
        <td>{fileUploadShareLocalUnit.displayNameWithType}</td>
      </tr>;
    }
  }), [
    handleItemSelect,
    handleSelectAll,
    selectedFileShareLocalUnits
  ]);

  return <Modal isOpen={isOpen}
                size="lg"
                toggle={onCancel}
                returnFocusAfterClose={true}
                autoFocus={false}>
    <ModalHeader className="h5" tag="h2">
      Share File
    </ModalHeader>
    <ModalBody>
      <Row className="mb-2">
        <Col className="font-weight-bold">
          Select the local units you would like share this file with
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomTable {...tableProps} />
        </Col>
      </Row>
    </ModalBody>
    <ModalFooter>
      <Button color="success"
              disabled={processing || !hasChanged}
              onClick={handleShare}>
        Share
      </Button>
      <Button color="secondary"
              onClick={handleCancel}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>;
};

export default FileShareModal;