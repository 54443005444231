import {memo, useCallback, useMemo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, Form, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikFileInput} from '@reasoncorp/kyber-js';

import {masterSalesCsvSchema} from '../../schemas/mega';
import {mega} from '../../types';

type Props = {
  isOpen: boolean
  onSubmit: (csvFileUpload: mega.CsvFileUpload, actions: FormikHelpers<mega.CsvFileUpload>) => void
  onToggle: () => void
  errors: string[][]
}

const MasterSalesCsvModal = ({
                               isOpen,
                               onSubmit,
                               onToggle,
                               errors = []
                             }: Props) => {
  const csvHasTooManyErrors = useMemo(() => {
    return errors.some(([_, msg]) => msg.length > 500);
  }, [
    errors
  ]);

  const handleToggle = useCallback((formikProps: FormikProps<any>) => {
    onToggle();
    formikProps.resetForm();
  }, [
    onToggle
  ]);

  const initialValues: mega.CsvFileUpload = useMemo(() => ({
    csvFile: null
  }), []);

  const renderError = useMemo(() => ([error, msg]: string[], i: number) => <ListGroupItem key={i}>
    <Row className="align-items-center w-100">
      <Col xs="1">
        <FontAwesomeIcon icon="exclamation-circle"
                         className="text-danger"
                         size="lg"/>
      </Col>
      <Col className="pl-lg-0" xs="11">
        {error} {msg}
      </Col>
    </Row>
  </ListGroupItem>, []);

  return (
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            validationSchema={masterSalesCsvSchema}
            onSubmit={onSubmit}>
      {(formikProps) => {
        return (
          <Modal scrollable
                 isOpen={isOpen}
                 toggle={() => handleToggle(formikProps)}
                 size="lg"
                 returnFocusAfterClose={true}
                 autoFocus={false}>
            <ModalHeader toggle={() => handleToggle(formikProps)} className="h5" tag="h2">
              {errors.length === 0 ? 'Import Data' : 'Resolve the following CSV error(s).'}
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={formikProps.handleSubmit}>
                {errors.length === 0 && <>
                  <Row className="mb-2">
                    <Col>
                      Uploading a CSV will clear the raw data.
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikFileInput autoFocus
                                       labelText="Upload a file"
                                       name="csvFile"/>
                    </Col>
                  </Row>
                </>}
                {errors.length > 0 && <>
                  <ListGroup flush>
                    {csvHasTooManyErrors && <ListGroupItem key={0}>
                      <Row className="align-items-center w-100">
                        <Col xs="1">
                          <FontAwesomeIcon icon="exclamation-circle"
                                           className="text-danger"
                                           size="lg"/>
                        </Col>
                        <Col className="pl-0" xs="11">
                          CSV file has too many errors. Correct and upload CSV file again.
                        </Col>
                      </Row>
                    </ListGroupItem>}
                    {!csvHasTooManyErrors && errors.map(renderError)}
                  </ListGroup>
                </>}
              </Form>
            </ModalBody>
            <ModalFooter>
              {errors.length === 0 && <>
                <Button
                  color="primary"
                  className="mr-1"
                  disabled={!formikProps.isValid || !formikProps.dirty || formikProps.isSubmitting}
                  onClick={formikProps.submitForm}>
                  Import
                </Button>
                <Button color="secondary"
                        onClick={() => handleToggle(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </>}
              {errors.length > 0 && <Button color="secondary"
                                            onClick={() => handleToggle(formikProps)}>
                Dismiss
              </Button>}
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(MasterSalesCsvModal);